body {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dashboard {
    display: inline-flex;
    width: 100%;
    height: 100%;
}

.stats {
    display: block;
    height: 100%;
    width: 50%;
}

.orders {
    display: block;
    height: 100%;
    width: 50%;
    overflow-y: scroll;
}

.orders::-webkit-scrollbar {
    width: 0 !important
}

.stats label {
    font-size: 3vmax;
    width: 100%;
}

.amount {
    font-weight: 500;
}

.stats div {
    box-sizing: border-box;
    height: 25%;
    width: 100%;
    border-top: 5px solid gray;
    border-right: 5px solid lightslategray;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
}

.order {
    display: flex;
    border-top: 5px solid lightblue;
    box-sizing: border-box;
}

.order label {
    font-size: 2.1vmax;
}

.orderItems {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 60%;
    margin-left: 10px;
}

.orderDetails {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    align-content: center;
    margin-left: 10px;
}

#date {
    width: 100%;
    font-size: 2.5vmax;
}

#name {
    display: block;
    font-size: 3vmax;
    width: 100%;
}

#item {
    display: block;
    width: 100%;
}

#orderTotal {
    width: 100%;
    font-size: 2.5vmax;
}

#product30 {
    font-weight: 500;
}
